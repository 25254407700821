import { memo } from 'react';
import clsx from 'clsx';
import useMemoSelector from 'hooks/useMemoSelector';

import { deviceParams } from 'store/reselect';

import { useImageStyles } from './styles';

const Image = ({ value, isAdView, isSingleColumn, className, columnCount }) => {
  const { deviceType } = useMemoSelector(deviceParams());
  const { root } = useImageStyles({ deviceType, columnCount });

  return (
    <div
      className={clsx(root, className, { center: !isAdView && isSingleColumn })}
    >
      {value.url && <img src={value.url} alt="Preview" />}
    </div>
  );
};

export default memo(Image);
