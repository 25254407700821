import { memo } from 'react';
import clsx from 'clsx';
import useMemoSelector from 'hooks/useMemoSelector';

import { deviceParams } from 'store/reselect';

import Image from './Image';
import Description from './Description';
import { useAdvertisementStyles } from './styles';

const Advertisement = ({ value }) => {
  const { deviceType } = useMemoSelector(deviceParams());
  const { root, content } = useAdvertisementStyles({
    variant: value.variant,
    deviceType,
  });

  const isImageTop = value.variant === 'imageTop';
  const isImageRight = value.variant === 'imageRight';

  return (
    <div
      className={clsx(root, 'advertisement', {
        reverse: isImageRight,
        mobileView: deviceType === 'mobile',
      })}
    >
      {value.image?.url && (
        <Image
          value={value.image}
          className={clsx('image', {
            imageCenter: isImageTop,
            mb16: value.title,
          })}
        />
      )}
      <div className={content}>
        {value.title && (
          <h2
            className={clsx({
              mb16: value.description,
              textCenter: isImageTop,
            })}
          >
            {value.title}
          </h2>
        )}
        {value.description && (
          <Description value={{ value: value.description }} />
        )}
      </div>
    </div>
  );
};

export default memo(Advertisement);
