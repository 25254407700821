import { memo } from 'react';
import clsx from 'clsx';

import { useDescriptionStyles } from './styles';

const Description = ({ value, className }) => {
  const { root } = useDescriptionStyles();

  return (
    <div className={clsx(root, className)}>
      <div
        className="description"
        dangerouslySetInnerHTML={{
          __html: value.value,
        }}
      />
    </div>
  );
};

export default memo(Description);
