import { makeStyles } from '@material-ui/core';

const imagWidthMapping = {
  mobile: '100%',
  tablet: '352px',
  desktop: '488px',
};

export const useContentBlockStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    '& .mb16': {
      marginBottom: '16px !important',
    },

    '& .mx-24': {
      margin: '24px 0',
    },

    '& .content-block': {
      maxWidth: '1000px',
      gap: 10,
      '& p': {
        margin: 0,
      },
    },

    width: '100%',
    '& .content': {
      background: '#003f8f',
      color: 'white',
      fontSize: '16px',
    },
    '& .close-button': {
      top: '-40px',
      right: '10px',
      position: 'absolute',
      backgroundColor: '#29C0A3',
    },
  },
}));

export const useAdvertisementStyles = makeStyles(() => ({
  root: {
    '&.mobileView': {
      flexDirection: 'column !important',
      alignItems: 'center',
    },
    '& .image': {
      width: ({ deviceType }) => imagWidthMapping[deviceType],
    },
    alignItems: 'flex-start',
    '&.reverse': {
      flexDirection: 'row-reverse',
    },
    '& .textCenter': {
      textAlign: 'center',
    },
    '& h2': {
      margin: 0,
      fontSize: '20px',
    },
    '& .imageCenter': {
      margin: 'auto',
    },
    display: ({ variant }) => (variant === 'imageTop' ? 'block' : 'flex'),
    color: 'white',
    gap: '24px',
  },

  content: {
    textAlign: 'left',
  },
}));

export const useTitleStyles = makeStyles(() => ({
  title: {
    margin: 0,
    color: 'white',
    fontSize: '24px',
    fontWeight: 'bold',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

export const useImageStyles = makeStyles(() => ({
  root: {
    '&.center': {
      width: '100%',
      height: '190px',
      margin: '0 auto',
    },
    width: ({ deviceType, columnCount }) =>
      columnCount === 3 && (deviceType === 'desktop' || deviceType === 'tablet')
        ? '100%'
        : imagWidthMapping[deviceType],
    minWidth: ({ deviceType, columnCount }) =>
      columnCount === 3 && (deviceType === 'desktop' || deviceType === 'tablet')
        ? '100%'
        : imagWidthMapping[deviceType],
    flexDirection: 'column',
    display: 'flex',
    '& img': {
      width: '100%',
      overflow: 'hidden',
      objectFit: 'cover',
      borderRadius: '4px',
    },
  },
}));

export const useDescriptionStyles = makeStyles(() => ({
  root: {
    '& > a': {
      color: '#6DB9FF',
    },
    '& > .description > p': {
      wordWrap: 'break-word',
    },
  },
}));

export const useButtonStyles = makeStyles(() => ({
  root: {
    textAlign: 'center',
  },
  link: {
    cursor: 'pointer',
    display: 'inline-flex',
    padding: '12px 40px',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '4px',
    gap: '10px',
    textDecoration: 'none',
    color: 'white',
    width: '100%',
    '&:hover': {
      color: 'white',
    },
    '&.cta': {
      background: '#65AE00',
    },
    '&.primary': {
      background: '#488DE7',
    },
    '&.secondary': {
      background: '#063A9E',
    },
  },
}));

export const useDividerStyles = makeStyles(() => ({
  root: {
    border: 0,
    padding: 0,
    height: '1px',
    display: 'block',
    borderTop: '1px solid #176CDB',
    borderBottom: '1px solid #176CDB',
  },
}));
